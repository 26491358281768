import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CurrentUserContext from "../context/CurrentUserContext";
import ResourceScaler from "../custom_editor/util/resourceScaler";
import { apiUrl } from "../util/config";
import Comment from "./components/comment";
import ElementBuilder from "./components/elementBuilder";
import CoolInput from "./components/general/coolInput";
import Modal from "./components/general/modal";
import InsertComment from "./components/insertComment";
import AddParentToResourceButton from "./components/micro_components/addParentToResourceButton";
import ChangePrivacy from "./components/micro_components/changePrivacyResourceDetails";
import PrivacyButton from "./components/micro_components/PrivacyButton";

const ResourceDetails = ({ resource, displayConfig }) => {
  const [resourceMeta, setResourceMeta] = useState([]);
  const [comments, setComments] = useState([]);
  const [loading, setLoading] = useState(true);

  const currentUser = useContext(CurrentUserContext);

  const [isPrivate, setIsPrivate] = useState(resource.isPrivate);

  const [isModalOpen, setModalOpen] = useState(false);
  const [newTitle, setNewTitle] = useState(resource.title);

  const navigate = useNavigate();

  const handleNewComment = (newComment, replyTo = null) => {
    setComments((prevComments) => {
      // If it's a top-level comment (not a reply), simply add it to the list
      if (!replyTo) {
        return [newComment, ...prevComments];
      }
      // Otherwise, it's a reply, so find the parent comment and update it
      const updatedComments = JSON.parse(JSON.stringify(prevComments)); // Deep copy to avoid direct state mutation

      const updateReplies = (comments) => {
        return comments.map((comment) => {
          if (comment.id === replyTo) {
            // Found the parent comment, add the new reply
            return {
              ...comment,
              reply_of_children: [
                newComment,
                ...(comment.reply_of_children || []),
              ],
            };
          } else if (comment.reply_of_children) {
            // Recursively update nested replies
            return {
              ...comment,
              reply_of_children: updateReplies(comment.reply_of_children),
            };
          }
          return comment;
        });
      };

      return updateReplies(updatedComments);
    });
  };

  useEffect(() => {}, [comments]);

  useEffect(() => {
    const fetchResourceDetails = async () => {
      try {
        const response = await fetch(
          `${apiUrl}/api/resource-meta?resourceId=${resource.id}`
        );

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        setResourceMeta(data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching resource details:", error);
        setLoading(false);
      }
    };

    fetchResourceDetails();
  }, [resource.id]);

  useEffect(() => {
    const fetchComments = async () => {
      try {
        const response = await fetch(
          `${apiUrl}/api/comments-for-resource?resourceId=${resource.id}`
        );

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const commentsData = await response.json();
        commentsData.sort(
          (b, a) => new Date(b.created_at) - new Date(a.created_at)
        );
        setComments(commentsData);
      } catch (error) {
        console.error("Error fetching comments:", error);
      }
    };

    fetchComments();
  }, [resource.id]);

  if (loading) {
    return <p>Loading resource details...</p>;
  }

  if (!resourceMeta.length) {
    return <p>No resource metadata found for the given ID.</p>;
  }

  const handleCopyResource = () => {
    // Construct the URL for the API call
    const url = `${apiUrl}/api/copy-resource?resourceId=${resource.id}&newTitle=${newTitle}`;

    fetch(url, {
      method: "GET", // Or 'POST', if your endpoint expects it
      credentials: "include", // Include cookies if your API requires authentication
      // Additional headers as needed
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          //alert(`Resource copied successfully! New Resource ID: ${data.resourceId}`);
          // Close the modal
          navigate(`/editing/${data.resourceId}`);
          setModalOpen(false);
        } else {
          alert("Failed to copy the resource.");
        }
      })
      .catch((error) => {
        console.error("Error copying resource:", error);
        alert("Error occurred while copying the resource.");
      });
  };

  const handleGeneratePdf = () => {
    // Construct the URL for the PDF generation
    let landscape =
      document.querySelector(".page-container").offsetHeight < 900
        ? true
        : false;

    const url = `${apiUrl}/api/generate-pdf?url=${window.location.href}&landscape=${landscape}`;
    // Use 'fetch' to call your backend endpoint
    // This example assumes you want to trigger a download directly
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        // Create a new object URL for the blob
        const url = window.URL.createObjectURL(blob);
        // Create a temporary link element and trigger a download
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `resource-${resource.id}.pdf`); // Set the file name for the download
        document.body.appendChild(link);
        link.click();
        // Clean up by revoking the object URL and removing the link element
        link.parentNode.removeChild(link);
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error("Error generating PDF:", error);
        alert("Failed to generate PDF. Please try again later.");
      });
  };

  return (
    <>
      <div className="category-container-wrapper">
        <h2>{resource.title}</h2>

        <p>
          By: {resource.author.firstname} {resource.author.lastname}
        </p>
        <div>
          <div style={{ display: "flex" }}>
            {resource.created_by === currentUser.currentUser.id ? (
              <a className="edit-button" href={`/editing/${resource.id}`}>
                {" "}
                Edit{" "}
              </a>
            ) : null}
            {resource.created_by === currentUser.currentUser.id ? (
              <PrivacyButton resource={resource} onSubmit={setIsPrivate} />
            ) : null}
            {resource.created_by === currentUser.currentUser.id && isPrivate ? (
              <ChangePrivacy
                isPrivate={true}
                permissions={resource.permissions}
                resourceID={resource.id}
                resource={resource}
              />
            ) : null}
          </div>
          <div style={{ display: "flex" }}>
            {displayConfig.allowPDF && (
              <button className="new-button" onClick={handleGeneratePdf}>
                <img src="/icons/download.svg" alt="download" />

                <p>Downlaod PDF</p>
              </button>
            )}

            {displayConfig.isCopyable && (
              <button className="new-button" onClick={() => setModalOpen(true)}>
                <img src="/icons/copy.svg" alt="copy" />
                <p>Copy Resource </p>
              </button>
            )}

            {displayConfig.allowShareWithParents && (
              <AddParentToResourceButton
                resourceId={resource.id}
                sosuUserId={currentUser.currentUser.id}
              />
            )}
          </div>
        </div>
        <div style={{ position: "relative" }}>
          <div className="resource-canvas">
            {<ElementBuilder jsonData={resourceMeta} />}
          </div>
          {displayConfig.zoomButtons && (
            <ResourceScaler position={"absolute"} />
          )}
        </div>
        {!displayConfig.doNotShowDescription && (
          <>
            <h3 style={{ textAlign: "left", marginBottom: "5px" }}>
              {" "}
              Description{" "}
            </h3>
            <p style={{ textAlign: "left", marginTop: "0px" }}>
              {resource.description}
            </p>{" "}
          </>
        )}
        <div>
          <ul>
            {comments.reverse().map((comment) => (
              <Comment
                key={comment.id}
                showButtons={displayConfig.noCommentTree && false}
                comment={comment}
                resource_id={resource.id}
                onNewComment={handleNewComment}
                collapseChildren={true}
              />
            ))}
            <InsertComment
              reply_to={null}
              resource_id={resource.id}
              unfolded={true}
              always_unfolded={true}
              onNewComment={handleNewComment}
            />
          </ul>
        </div>
      </div>

      <Modal isOpen={isModalOpen} onClose={() => setModalOpen(false)}>
        <div>
          <h2>Copy Resource</h2>
          <p>
            By copying the resource you're making your own version that you can
            edit, are you sure you want this?
          </p>

          <CoolInput label="New Title" onChange={setNewTitle} />

          <button onClick={handleCopyResource}>Copy</button>
        </div>
      </Modal>
    </>
  );
};

export default ResourceDetails;
