import ResourceList from "./ResourceList";
import CategoryCard from "./components/categories/categoryCard";
import CategoryRow from "./components/common_forum/CategoryRow";
import Breadcrumbs from "./components/general/breadCrumbs";

function collectAndSortResources(category) {
  let allResources = [];
  // Helper function to recursively collect resources
  const collectResources = (cat) => {
    if (cat.resources && cat.resources.length > 0) {
      allResources = allResources.concat(cat.resources);
    }
    if (cat.subcategories && cat.subcategories.length > 0) {
      cat.subcategories.forEach((subcat) => collectResources(subcat));
    }
  };
  // Start the recursion with the top-level category
  collectResources(category);

  return allResources;
}

const CategoryContainer = ({ category, categories, currentPath, postType }) => {
  const imageUrl = category
    ? category.file && category.file.length > 0
      ? category.file[0].path.replace(/\\/g, "/")
      : "path/to/default/image.jpg"
    : "";

  const headerStyle = {
    display: "flex",
    backgroundImage: `url(/${imageUrl})`, // Replace with your arrow image
    backgroundSize: "cover", // Cover the area
    backgroundPosition: "center", // Center the image
    height: "180px",
    borderRadius: "15px",
    width: "100%",
  };
  const headerTextBox = {
    height: "100%",
    display: "flex",
    position: "relative",
  };
  const headerText = {
    position: "absolute",
    bottom: "0",
    paddingLeft: "30px",
    color: "white",
    textShadow: "0 1px 2px rgba(0,0,0,.5)",
  };

  if (category) {
    return (
      <>
        <div className="category-container-wrapper">
          <h2>{postType.name}</h2>
          <Breadcrumbs path={currentPath} />

          <div className="innerCatHolder">
            <div style={headerStyle}>
              <div style={headerTextBox}>
                <h2 style={headerText}>{category.Name}</h2>
              </div>
            </div>
            <a className="make-new" href={`/add_new/${postType.id}`}>
              <span>+</span> Make new
            </a>
            <p>{category.description}</p>
            {postType.displayConfig.allowCategoryCards &&
              category.subcategories.length > 0 && (
                <div
                  className="flex"
                  style={{ width: "100%", marginBottom: "35px" }}
                >
                  {category.subcategories.map((sub) => {
                    return <CategoryCard key={sub.ID} category={sub} />;
                  })}
                </div>
              )}
            {postType.name === "Forum" && category.subcategories.length > 0 && (
              <div
                className="flex"
                style={{
                  width: "100%",
                  marginBottom: "35px",
                  flexDirection: "column",
                }}
              >
                {category.subcategories.map((sub) => (
                  <CategoryRow key={sub.ID} category={sub} />
                ))}
              </div>
            )}
          </div>
          <h2>Most recent {postType.name}</h2>
          <ResourceList
            categoryId={category.ID}
            resources={category.resources}
            currentPath={currentPath}
            postType={postType}
            displayConfig={postType.displayConfig}
          />
        </div>
      </>
    );
  }

  {
    const allResources = categories
      .map((category) => collectAndSortResources(category))
      .flat();
    allResources.sort(
      (a, b) => new Date(b.created_at) - new Date(a.created_at)
    );
    let showAllConfig = postType.displayConfig;
    showAllConfig.categoryName = true;
    return (
      <>
        <div className="category-container-wrapper">
          <h2>{postType.name}</h2>
          <Breadcrumbs path={currentPath} />
          {showAllConfig.allowCategoryCards && (
            <div className="flex" style={{ flexWrap: "wrap" }}>
              {categories.map((category) => (
                <CategoryCard key={category.ID} category={category} />
              ))}
            </div>
          )}
          {postType.name === "Forum" && (
            <div
              className="flex"
              style={{
                width: "100%",
                marginBottom: "35px",
                flexDirection: "column",
              }}
            >
              {categories.map((sub) => (
                <CategoryRow key={sub.ID} category={sub} />
              ))}
            </div>
          )}
          <a className="make-new" href={`/add_new/${postType.id}`}>
            <span>+</span> Make new
          </a>

          <h2>Most recent {postType.name}</h2>
          <ResourceList
            resources={allResources}
            currentPath={currentPath}
            postType={postType}
            displayConfig={showAllConfig}
          />
        </div>
      </>
    );
  }
};

export default CategoryContainer;
