import React, { useState } from "react";

function Star({
  isOutlined,
  isFilled,
  starNumber,
  onClick,
  onMouseEnter,
  onMouseLeave,
}) {
  return (
    <img
      style={{ width: "25px", height: "25px" }}
      src={`/icons/star${
        isOutlined ? "-outlined" : isFilled ? "-filled" : ""
      }.svg`}
      alt="star"
      onClick={() => onClick(starNumber)}
      onMouseEnter={() => onMouseEnter(starNumber)}
      onMouseLeave={onMouseLeave}
    />
  );
}

export default function InteractiveSVG({
  currentRating,
  currentUserRating,
  onClick,
  totalUsersRated,
}) {
  const [filledStars, setFilledStars] = useState(currentRating || 0);
  const [userRating, setUserRating] = useState(currentUserRating || 0);
  const [hoveredStar, setHoveredStar] = useState(null);
  const [totalReviews, setTotalReviews] = useState(totalUsersRated || 0);

  const totalStars = 5;

  const handleStarClick = (starNumber) => {
    let newTotalReviews = totalReviews;
    let pastRatings = filledStars * totalReviews;

    if (userRating === 0) {
      newTotalReviews += 1; // Increment the totalUsersRated count if it's the user's first rating
      setTotalReviews(newTotalReviews);
    } else {
      // If the user has already rated, we need to subtract their previous rating from the pastRatings
      pastRatings -= userRating;
    }

    const newAverageRating = (pastRatings + starNumber) / newTotalReviews;

    setUserRating(starNumber);
    setFilledStars(newAverageRating);
    onClick(starNumber); // Callback with the new rating
  };

  const handleStarMouseEnter = (starNumber) => {
    setHoveredStar(starNumber);
  };

  const handleStarMouseLeave = () => {
    setHoveredStar(null);
  };

  return (
    <div
      style={{ display: "flex", flexDirection: "row", width: 25 * totalStars }}
    >
      {Array.from({ length: totalStars }).map((_, index) => (
        <Star
          isFilled={index < filledStars}
          isOutlined={index < userRating || index < hoveredStar}
          key={index}
          x={index * 25}
          starNumber={index + 1}
          onClick={handleStarClick}
          onMouseEnter={handleStarMouseEnter}
          onMouseLeave={handleStarMouseLeave}
        />
      ))}
    </div>
  );
}
