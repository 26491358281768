import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ResourceList from "../../containers/ResourceList";
import Comment from "../../containers/components/comment";
import NameInitialsAvatar from "../../containers/components/micro_components/NameInitialsAvatar";
import AddUserForm from "../../containers/components/users/createUser";
import CurrentUserContext from "../../context/CurrentUserContext";
import PathsContext from "../../context/PathsContext";
import { apiUrl } from "../../util/config";
import "./profile_page.css";

export default function ProfilePage() {
  const [userComments, setUserComments] = useState([]);
  const [userRatings, setUserRatings] = useState([]);
  const [userSavedResources, setUserSavedResources] = useState([]);
  const [userCreatedResources, setUserCreatedResources] = useState([]);
  const [selectedPostTypeIdForComments, setSelectedPostTypeIdForComments] =
    useState("");
  const [selectedPostTypeIdForRatings, setSelectedPostTypeIdForRatings] =
    useState("");
  const [selectedPostTypeIdForSaved, setSelectedPostTypeIdForSaved] =
    useState("");
  const [selectedPostTypeIdForCreated, setSelectedPostTypeIdForCreated] =
    useState("");

  const { currentUser } = useContext(CurrentUserContext);
  const [postTypes, setPostTypes] = useState([]);

  const allPaths = useContext(PathsContext).allPaths;

  const extractUniquePostTypes = (allItems) => {
    const allPostTypeInfos = allItems
      .map((item) => item.postTypeInformation)
      .filter(Boolean);

    const uniquePostTypes = allPostTypeInfos.reduce((acc, current) => {
      const x = acc.find((item) => item.id === current.id);
      if (!x) {
        return acc.concat([current]);
      } else {
        return acc;
      }
    }, []);

    return uniquePostTypes;
  };

  useEffect(() => {
    const fetchData = async () => {
      const commentsResponse = await fetch(`${apiUrl}/api/user-comments`, {
        credentials: "include",
      });
      const commentsData = await commentsResponse.json();
      setUserComments(commentsData);
      const ratingsResponse = await fetch(`${apiUrl}/api/user-ratings`, {
        credentials: "include",
      });
      const ratingsData = await ratingsResponse.json();
      setUserRatings(ratingsData);
      const savedResourcesResponse = await fetch(
        `${apiUrl}/api/user-saved-resources`,
        {
          credentials: "include",
        }
      );
      const savedResourcesData = await savedResourcesResponse.json();
      setUserSavedResources(savedResourcesData);
      const createdResourcesResponse = await fetch(
        `${apiUrl}/api/user-created-resources`,
        {
          credentials: "include",
        }
      );
      const createdResourcesData = await createdResourcesResponse.json();
      setUserCreatedResources(createdResourcesData);
    };
    fetchData();
  }, []);

  useEffect(() => {
    const allItems = [
      ...userComments,
      ...userRatings,
      ...userSavedResources,
      ...userCreatedResources,
    ];
    const extractedPostTypes = allItems
      .map((item) => item.postTypeInformation)
      .filter(
        (value, index, self) =>
          value && self.findIndex((t) => t.id === value.id) === index
      ); // Ensure unique and defined post types
    setPostTypes(extractedPostTypes);
  }, [userComments, userRatings, userSavedResources, userCreatedResources]); // React to changes in any user data

  useEffect(() => {
    const allItems = [
      ...userComments,
      ...userRatings,
      ...userSavedResources,
      ...userCreatedResources,
    ];
    const uniquePostTypes = extractUniquePostTypes(allItems);
    setPostTypes(uniquePostTypes);
  }, [userComments, userRatings, userSavedResources, userCreatedResources]);

  useEffect(() => {
    if (postTypes.length > 0) {
      setSelectedPostTypeIdForComments(postTypes[0].id.toString());
      setSelectedPostTypeIdForRatings(postTypes[0].id.toString());
      setSelectedPostTypeIdForSaved(postTypes[0].id.toString());
      setSelectedPostTypeIdForCreated(postTypes[0].id.toString());
    }
  }, [postTypes]);

  if (!currentUser) {
    window.location.reload();
    return <div>Loading user data...</div>;
  }
  const renderUserComments = (comments) => {
    return comments.map(
      (comment) =>
        allPaths && (
          <React.Fragment key={comment.id}>
            <div className="user-comments-resource-holder">
              <Link
                style={{ textDecoration: "none" }}
                to={`${allPaths.get(comment.resource_id)}`}
              >
                <div className="flex just-left crh-title">
                  <p
                    className="flex just-left align-items-center"
                    style={{ margin: "0px" }}
                  >
                    <NameInitialsAvatar
                      firstName={currentUser.firstname}
                      lastName={currentUser.lastname}
                      color={currentUser.color}
                      height="20px"
                      width="20px"
                      fontSize="10px"
                    />
                    <p style={{ margin: "0px" }}>
                      {currentUser.firstname} {currentUser.lastname} commented
                      on
                    </p>

                    <b>{comment.title} </b>
                  </p>
                  <p
                    className="flex just-left align-items-center"
                    style={{ margin: "0px" }}
                  >
                    posted by
                    <NameInitialsAvatar
                      firstName={comment.authorDetails.firstname}
                      lastName={comment.authorDetails.lastname}
                      color={comment.authorDetails.color}
                      height="20px"
                      width="20px"
                      fontSize="10px"
                    />
                    <p style={{ margin: "0px" }}>
                      {comment.authorDetails.firstname}{" "}
                      {comment.authorDetails.lastname}
                    </p>
                  </p>
                </div>
              </Link>
              <div
                style={{
                  padding: "25px",
                  paddingTop: "6px",
                  paddingBottom: "2px",
                }}
              >
                <Comment
                  comment={comment}
                  resource_id={comment.resource_id}
                  onNewComment={() => {}}
                  showButtons={false}
                />
              </div>
            </div>
          </React.Fragment>
        )
    );
  };

  const renderPostTypeSelect = (
    selectedPostTypeId,
    setSelectedPostTypeId,
    label
  ) => {
    return (
      <>
        <div className="profile-box-title">
          <h2 className="resource-label">{label}: </h2>
        </div>
        <div className="resource-buttons">
          {postTypes.map((postType) => (
            <button
              key={postType.id}
              style={{
                backgroundColor:
                  postType.id.toString() === selectedPostTypeId
                    ? "#4CAF50"
                    : "#f2f2f2",
                color:
                  postType.id.toString() === selectedPostTypeId
                    ? "white"
                    : "black",
              }}
              value={postType.id}
              onClick={() => setSelectedPostTypeId(postType.id.toString())}
            >
              {postType.name} ({postType.post_name_plural})
            </button>
          ))}
        </div>
      </>
    );
  };

  const displayConfig = {
    title: true,
    categoryName: true,
    description: true,
    author: true,
    date: true,
    userRating: true,
    comments: true,
    status: true,
    savedByUser: true,
  };

  return (
    <div style={{ width: "66%" }}>
      <div className="outer-profile-box">
        {renderPostTypeSelect(
          selectedPostTypeIdForComments,
          setSelectedPostTypeIdForComments,
          "Your Comments"
        )}
        {/* Filter and render comments based on selectedPostTypeIdForComments */}

        <div className="profile-box">
          {renderUserComments(
            userComments.filter(
              (comment) =>
                comment.postTypeInformation.id.toString() ===
                selectedPostTypeIdForComments
            )
          )}
        </div>
      </div>

      <div className="outer-profile-box">
        {renderPostTypeSelect(
          selectedPostTypeIdForRatings,
          setSelectedPostTypeIdForRatings,
          "Rated posts"
        )}
        {/* Filter and render ratings based on selectedPostTypeIdForRatings */}

        <div className="profile-box">
          <ResourceList
            resources={userRatings.filter(
              (rating) =>
                rating.postTypeInformation.id.toString() ===
                selectedPostTypeIdForRatings
            )}
            displayConfig={displayConfig}
          />
        </div>
      </div>
      <div className="outer-profile-box">
        {renderPostTypeSelect(
          selectedPostTypeIdForSaved,
          setSelectedPostTypeIdForSaved,
          "Saved posts"
        )}
        {/* Filter and render saved resources based on selectedPostTypeIdForSaved */}

        <div className="profile-box">
          <ResourceList
            resources={userSavedResources.filter(
              (resource) =>
                resource.postTypeInformation.id.toString() ===
                selectedPostTypeIdForSaved
            )}
            displayConfig={displayConfig}
          />
        </div>
      </div>
      <div className="outer-profile-box">
        {renderPostTypeSelect(
          selectedPostTypeIdForCreated,
          setSelectedPostTypeIdForCreated,
          "Your posts"
        )}
        {/* Filter and render created resources based on selectedPostTypeIdForCreated */}

        <div className="profile-box">
          <ResourceList
            resources={userCreatedResources.filter(
              (resource) =>
                resource.postTypeInformation.id.toString() ===
                selectedPostTypeIdForCreated
            )}
            displayConfig={displayConfig}
          />
        </div>
      </div>

      <AddUserForm onhandleSubmit={() => window.alert("User created")} />
    </div>
  );
}
