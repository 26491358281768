import axios from "axios";
import React, { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom"; // Import Link from react-router-dom for navigation
import NameInitialsAvatar from "./containers/components/micro_components/NameInitialsAvatar";
import CurrentUserContext from "./context/CurrentUserContext";
import "./styles/header.css";
import { apiUrl } from "./util/config";
import isAdmin from "./util/isAdmin";

const Header = (types) => {
  const givenTypes = Object.values(types.types);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const userContext = useContext(CurrentUserContext);
  const [isHovered, setIsHovered] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      const response = await axios.post(
        `${apiUrl}/api/logout`,
        {},
        { withCredentials: true }
      );

      if (response.status === 200) {
        navigate("/");

        window.location.reload();
      } else {
        console.error("Logout failed:", response.data.message);
      }
    } catch (error) {
      console.error("Logout failed:", error);
    }
  };

  return (
    <>
      <header>
        <Link to="/" className="link">
          <h1>Pycipedia</h1>
        </Link>

        <nav>
          <Link to="/min_sida" className="link">
            My page
          </Link>
          {givenTypes.map((type) => {
            if (type.displayConfig.showInHeader) {
              return (
                <Link key={type.name} to={`/${type.name}`} className="link">
                  {type.name}
                </Link>
              );
            }
            return null;
          })}

          <Link to="/bildBank" className="link">
            BildBank
          </Link>
          <Link to="/about" className="link">
            Om Pycipedia
          </Link>
          {isAdmin(userContext) ? (
            <Link className="link" onClick={toggleMenu}>
              Admin Menu
            </Link>
          ) : null}
          <div
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            style={{ position: "relative", display: "inline-block" }}
          >
            <div className="link">
              <NameInitialsAvatar
                firstName={`${userContext.currentUser.firstname}`}
                lastName={`${userContext.currentUser.lastname}`}
                color={`${userContext.currentUser.color}`}
                height="40px"
                width="40px"
                fontSize="15px"
              />
            </div>
            <div className={`dropdown-menu ${isHovered ? "visible" : ""}`}>
              <Link to="/profile" className="dropdown-item">
                Profile
              </Link>
              <Link onClick={handleLogout} className="dropdown-item">
                Logout
              </Link>
            </div>
          </div>
        </nav>
      </header>
      <div
        className={`overlay ${isMenuOpen ? "visible" : ""}`}
        onClick={toggleMenu}
      />
      <AdminMenu toggleMenu={toggleMenu} isMenuOpen={isMenuOpen} />
    </>
  );
};

const AdminMenu = ({ toggleMenu, isMenuOpen }) => (
  <div className={`admin-menu ${isMenuOpen ? "visible" : ""}`}>
    <Link className="admin-menu-link" to="/admin/types" onClick={toggleMenu}>
      Types
    </Link>
    <Link
      className="admin-menu-link"
      to="/admin/categories"
      onClick={toggleMenu}
    >
      Categories
    </Link>
    <Link className="admin-menu-link" to="/admin/users" onClick={toggleMenu}>
      Users
    </Link>
    <Link
      className="admin-menu-link"
      to="/admin/user-parents"
      onClick={toggleMenu}
    >
      Sosu to parents
    </Link>
  </div>
);

export default Header;
