import React from 'react';

const HomePage = () => {
  return (
    <div>
      <h1>Välkommen till Pycipedia</h1>
      <p>Den här sidan kommer snart uppdateras med innehåll</p>
    </div>
  );
};

export default HomePage;