export default function HeartIcon({ isSaved, onToggle }) {
  const handleClick = () => {
    onToggle(!isSaved);
  };

  return (
    <div onClick={handleClick} style={{ height: "25px" }}>
      <img
        src={`/icons/${isSaved ? "heart-filled" : "heart"}.svg`}
        alt="heart"
        width={25}
      />
    </div>
  );
}
