import React from "react";
import { Link } from "react-router-dom"; // Assuming you're using React Router for navigation

const styles = {
  textDecoration: "none",
  color: "black",
  fontSize: "16px",
};

const Breadcrumbs = () => {
  const path = window.location.pathname;
  const pathSegments = path.split("/").filter(Boolean).map(decodeURIComponent); // Split the path and remove any empty strings
  const breadcrumbLinks = pathSegments.map((segment, index, arr) => {
    const link = `/${arr.slice(0, index + 1).join("/")}`; // Create the link for this breadcrumb

    return (
      <React.Fragment key={link}>
        <Link style={styles} to={link}>
          {segment}
        </Link>
        {index < arr.length - 1 && " > "}
      </React.Fragment>
    );
  });

  return (
    <div style={{ textAlign: "left" }}>
      <Link style={styles} to="/">
        Home
      </Link>
      {breadcrumbLinks.length > 0 && " > "}
      {breadcrumbLinks}
    </div>
  );
};

export default Breadcrumbs;
