import React from "react";
export default function AboutPage() {
  return (
    <div style={{ textAlign: "left", margin: "30px", maxWidth: "500px" }}>
      <h1>Om Pycipedia</h1>
      <p style={{ fontSize: "16px" }}>
        Pycipedia är mötesplatsen för familjebehandlare och boendestödjare som
        vill dela och ta del av material. På Pycipedia är tanken att du som
        loggat in ska lägga in material som du gjort och ta del av material
        andra tagit fram. Det kan vara ett bildstöd som du tycker var
        framgångsrikt i en familj, en film du använder dig av i mötet med klient
        eller forskning kring socialt arbete.{" "}
      </p>

      <p style={{ fontSize: "16px" }}>
        PYCIPEDIA är för närvarande i en utvecklings- och testfas vilket betyder
        att viss funktionalitet kan ändras, läggas till eller tas bort baserad
        på input som vi får från dom som använder PYCIPEDIA. Tveka inte att
        skicka dina synpunkter på PYCIPEDIA till oss.
      </p>

      <p style={{ fontSize: "16px" }}>
        PYCIPEDIA är utvecklat på IT Universitetet i Köpenhamn baserat på bland
        annat co-design aktiviteter tillsammans med socialarbetare och PYC
        utbildare. Arbetet med PYCIPEDIA är del av ett större samarbetsprojekt
        mellan IT Universitetet i Köpenhamn, Kungliga Tekniska Högskolan i
        Stockholm och FoU centrum i Östergötland. Besök socialcare.itu.dk för
        mer information om samarbetet och dess partner.
      </p>

      <h4>
        Kontakta gärna Erik Grönvall, lektor på IT Universitetet med synpunkter,
        frågor etc via email: erig@itu.dk.
      </h4>
    </div>
  );
}
