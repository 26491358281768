import { Link } from "react-router-dom";
import "./styles/footer.css";

export default function Footer() {
  return (
    <footer>
      <p>
        PYCIPEDIA – Skapad av och för socialarbetare. Besök
        <Link to="/about"> Pycipedia.se/about </Link>
        för mer information.
      </p>
    </footer>
  );
}
